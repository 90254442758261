import { createSlice } from '@reduxjs/toolkit';
import { TestiState, Testi } from 'types/testimonial';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: TestiState = {
  isLoading: false,
  error: null,
  testimonials: [],
  testimonials_published: [],
};

const slice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Teams
    getTestiSuccess(state, action) {
      state.isLoading = false;
      state.testimonials = action.payload;
    },

    // GET Teams
    getTestiPublishSuccess(state, action) {
      state.isLoading = false;
      state.testimonials = action.payload;
    },

    // Update Team
    updateTestiSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTestimonials() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Testi[] } } } = await axios.get(
        '/api/testimoni/all'
      );
      dispatch(slice.actions.getTestiSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function updateTesti(payload: any, id: string) {
  try {
    const response: {
      data: {
        message: string;
      };
    } = await axios.put(`/api/publish-testimoni/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.message;
  }
}

// ----------------------------------------------------------------------

export async function deleteTesti(id: string) {
  try {
    const response: {
      data: {
        message: string;
      };
    } = await axios.delete(`/api/delete-teams/${id}`);
    return response.data.message;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error.message;
  }
}

// ----------------------------------------------------------------------

export function getTestimonialsPublish() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Testi[] } } } = await axios.get(
        '/api/testimoni'
      );
      dispatch(slice.actions.getTestiPublishSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
