// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    materi: path(ROOTS_DASHBOARD, '/materi'),
    ukom: path(ROOTS_DASHBOARD, '/ukom'),
    kamus: path(ROOTS_DASHBOARD, '/kamus'),
    materiSaya: path(ROOTS_DASHBOARD, '/our-course'),
    transaksi: path(ROOTS_DASHBOARD, '/transaksi'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  ukom: {
    root: path(ROOTS_DASHBOARD, '/ukom'),
    list: path(ROOTS_DASHBOARD, '/ukom/list'),
    newessay: path(ROOTS_DASHBOARD, '/ukom/new/essay'),
    newkuesioner: path(ROOTS_DASHBOARD, '/ukom/new/kuesioner'),
    details: (id: string) => path(ROOTS_DASHBOARD, `/ukom/details/${id}`),
    editkuesioner: (id: string) => path(ROOTS_DASHBOARD, `/ukom/edit/kuesioner/${id}`),
    editessay: (id: string) => path(ROOTS_DASHBOARD, `/ukom/edit/essay/${id}`),
  },
  kamus: {
    root: path(ROOTS_DASHBOARD, '/kamus'),
    list: path(ROOTS_DASHBOARD, '/kamus/list'),
    new: path(ROOTS_DASHBOARD, '/kamus/new'),
    details: (id: string) => path(ROOTS_DASHBOARD, `/kamus/details/${id}`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/kamus/product/${name}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/kamus/edit/${id}`),
  },
  materi: {
    root: path(ROOTS_DASHBOARD, '/materi'),
    list: path(ROOTS_DASHBOARD, '/materi/list'),
    new: path(ROOTS_DASHBOARD, '/materi/new'),
    details: (id: string) => path(ROOTS_DASHBOARD, `/materi/details/${id}`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/materi/product/${name}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/materi/edit/${id}`),
  },
  materiSaya: {
    root: path(ROOTS_DASHBOARD, '/kelas-saya'),
    list: path(ROOTS_DASHBOARD, '/kelas-saya/list'),
    view: (payload: any) => path(ROOTS_DASHBOARD, `/kelas-saya/list-bab/${payload.id}`),
    kamusDetails: (payload: any) =>
      path(ROOTS_DASHBOARD, `/kelas-saya/kamus-details/${payload.id}`),
    play: (payload: any) => path('/', `list-play/${payload.name}`),
    ukom_work: (payload: any) => path('/', `ukom-work/${payload.id}`),
    ukom_discussion: (payload: any) => path('/', `ukom-discussion/${payload.id}`),
    ebook_details: (payload: any) => path(ROOTS_DASHBOARD, `/kelas-saya/ebook-details/${payload.id}`),
  },
  transaksi: {
    root: path(ROOTS_DASHBOARD, '/transactions'),
    list: path(ROOTS_DASHBOARD, '/transactions/list'),
    new: path(ROOTS_DASHBOARD, '/transactions/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/transactions/${id}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/transactions/product/${name}/edit`),
  },
  qoutes: {
    root: path(ROOTS_DASHBOARD, '/qoutes'),
    list: path(ROOTS_DASHBOARD, '/qoutes/list'),
    new: path(ROOTS_DASHBOARD, '/qoutes/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/qoutes/edit/${id}`),
  },
  perpustakaan: {
    root: path(ROOTS_DASHBOARD, '/perpustakaan'),
    list: path(ROOTS_DASHBOARD, '/perpustakaan/list'),
    new: path(ROOTS_DASHBOARD, '/perpustakaan/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/perpustakaan/edit/${id}`),
  },
  digitalLibrary: {
    root: path(ROOTS_DASHBOARD, '/digital-library'),
    list: path(ROOTS_DASHBOARD, '/digital-library/list'),
    new: path(ROOTS_DASHBOARD, '/digital-library/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/digital-library/view/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/digital-library/edit/${id}`),
  },
  review: path(ROOTS_DASHBOARD, '/reviews-books'),
  testimonial: path(ROOTS_DASHBOARD, '/testimonial'),
  timPenyusun: {
    root: path(ROOTS_DASHBOARD, '/tim-penyusun'),
    new: path(ROOTS_DASHBOARD, '/tim-penyusun/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/tim-penyusun/edit/${id}`),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    edit: (title: string, id: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}/${id}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  banner: {
    root: path(ROOTS_DASHBOARD, '/banner'),
    new: path(ROOTS_DASHBOARD, '/banner/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/banner/edit/${id}`),
  },
};

// export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
