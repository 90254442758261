// import jwtDecode from 'jwt-decode';
// routes
// import { PATH_AUTH } from 'routes/paths';
//
import axios from './axios';
import Cookies from 'js-cookie';

// ----------------------------------------------------------------------

type UserTypes = {
  first_name: string;
  last_name: string;
  image: string | null;
  role: string;
  univ: string | null;
};

const setSession = async (
  accessToken: string | null,
  user: UserTypes | null,
  remember?: boolean
) => {
  if (accessToken && user) {
    const session = {
      token: accessToken,
      user,
    };
    Cookies.set('user', JSON.stringify(session), {
      domain: process.env.REACT_APP_DOMAIN,
      expires: remember ? 30 : 7,
    });

    axios.defaults.headers.common.token = `Bearer|${accessToken}`;
  } else {
    Cookies.remove('user', {
      domain: process.env.REACT_APP_DOMAIN,
    });
    delete axios.defaults.headers.common.token;
  }
};

export { setSession };
