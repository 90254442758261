import { createSlice } from '@reduxjs/toolkit';
import { DetailAnalyticsTransactions, AnalyticsTransactionsState } from 'types/analytics';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: AnalyticsTransactionsState = {
  isLoadingTransaction: false,
  error: null,
  transactions: [],
  sortBy: null,
};

const slice = createSlice({
  name: 'analytic_transaction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingTransaction = true;
    },

    // GET Analytics
    getTransactionSuccess(state, action) {
      state.isLoadingTransaction = false;
      state.transactions = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingTransaction = false;
      state.error = action.payload;
    },

    //  SORT & FILTER Analytics
    sortByAnalyticTransaction(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByAnalyticTransaction } = slice.actions;

// ----------------------------------------------------------------------

export function getTransactions() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: DetailAnalyticsTransactions[] } } = await axios.get(
        '/api/analytics/transactions'
      );
      const temp: number[] = [];
      // eslint-disable-next-line array-callback-return
      await response.data.result.map((data) => {
        temp.push(data.count);
      });
      dispatch(slice.actions.getTransactionSuccess(temp));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
