import { createSlice } from '@reduxjs/toolkit';
import { BannerState, BannerNew, Page } from 'types/banner';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: BannerState = {
  isLoadingBanner: false,
  error: null,
  banners: [],
  pages: [],
  banner: null,
  sortBy: null,
  create: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingBanner = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingBanner = false;
      state.error = action.payload;
    },

    // GET Banners
    getBannersSuccess(state, action) {
      state.isLoadingBanner = false;
      state.banners = action.payload;
    },

    // GET Pages
    getPagessSuccess(state, action) {
      state.isLoadingBanner = false;
      state.pages = action.payload;
    },

    // GET Banner
    getBannerSuccess(state, action) {
      state.isLoadingBanner = false;
      state.banner = action.payload;
    },

    // Create Banner
    createBannerSuccess(state, action) {
      state.isLoadingBanner = false;
      state.create = action.payload;
    },

    // Update Banner
    updateBannerSuccess(state, action) {
      state.isLoadingBanner = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoadingBanner = false;
      state.create = null;
    },

    //  SORT & FILTER Banner
    sortByBanner(state, action) {
      state.sortBy = action.payload;
    },

    filterBanner(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByBanner, filterBanner } = slice.actions;

// ----------------------------------------------------------------------

export function getBanners() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: BannerNew[] } } } = await axios.get('/api/banner');
      dispatch(slice.actions.getBannersSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPages() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Page[] } } } = await axios.get('/api/pages');
      dispatch(slice.actions.getPagessSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBanner(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: BannerNew } } } = await axios.get(
        '/api/detail-banner/' + id
      );
      dispatch(slice.actions.getBannerSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createBanner(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: any;
      } = await axios.post('/api/create-banner', payload);
      dispatch(slice.actions.createBannerSuccess(response.data.message));
      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBanner(payload: any, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-banner/${id}`, payload);
      dispatch(slice.actions.updateBannerSuccess(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBanner(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-banner/${id}`);
      dispatch(slice.actions.updateBannerSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetCreateBanner() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
