import { createSlice } from '@reduxjs/toolkit';
import { UsersStateNew, UsersNew } from 'types/user';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: UsersStateNew = {
  isLoadingUsers: false,
  error: null,
  users: [],
  user: null,
  sortBy: null,
  create: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingUsers = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingUsers = false;
      state.error = action.payload;
    },

    // GET Users
    getUsersSuccess(state, action) {
      state.isLoadingUsers = false;
      state.users = action.payload;
    },

    // GET User
    getUserSuccess(state, action) {
      state.isLoadingUsers = false;
      state.user = action.payload;
    },

    // Create User
    createUserSuccess(state, action) {
      state.isLoadingUsers = false;
      state.create = action.payload;
    },

    // Update User
    updateUserSuccess(state, action) {
      state.isLoadingUsers = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoadingUsers = false;
      state.create = null;
    },

    //  SORT & FILTER Team
    sortByTeam(state, action) {
      state.sortBy = action.payload;
    },

    filterTeam(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByTeam, filterTeam } = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: UsersNew[] } } } = await axios.get('/api/users');
      dispatch(slice.actions.getUsersSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: UsersNew } } } = await axios.get(
        '/api/detail-user/' + id
      );
      dispatch(slice.actions.getUserSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserStudent() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: UsersNew } } } = await axios.get(
        '/api/detail-user'
      );
      dispatch(slice.actions.getUserSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUserAdmin(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/register/admin', payload);
      dispatch(slice.actions.createUserSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser(payload: any, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-user/${id}`, payload);
      dispatch(slice.actions.updateUserSuccess(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export async function updateUserStudent(payload: any) {
  try {
    const response: {
      data: {
        message: string;
      };
    } = await axios.put(`/api/update-user`, payload);
    dispatch(slice.actions.updateUserSuccess(response.data.message));
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error;
  }
}

export async function updatePassword(payload: any) {
  try {
    const response: {
      data: {
        message: string;
      };
    } = await axios.put(`/api/update-password`, payload);
    dispatch(slice.actions.updateUserSuccess(response.data.message));
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error;
  }
}

export async function updatePasswordFromAdmin(payload: any, id: string) {
  try {
    const response: {
      data: {
        message: string;
      };
    } = await axios.put(`/api/update-password-admin/${id}`, payload);
    dispatch(slice.actions.updateUserSuccess(response.data.message));
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error;
  }
}

// ----------------------------------------------------------------------

export function deleteUser(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-user/${id}`);
      dispatch(slice.actions.updateUserSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetCreateTeam() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
