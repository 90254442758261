import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Tooltip, Typography } from '@mui/material';
// hooks
import useAuth from 'hooks/useAuth';
// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import MyAvatar from 'components/MyAvatar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { user } = useAuth();

  const handleShowUniv = (value: string | null | undefined, tool: boolean): string => {
    let result = '';
    if (tool) {
      if (value && value !== 'undefined' && value !== 'null') {
          result = value;
      }
    }
    if (!tool) {
      if (value && value !== 'undefined' && value !== 'null') {
        if (value.length > 15) {
          result = value.substring(0, 15) + "...";
        }
        if (value.length < 15) {
          result = value;
        }
      }
    }
    return result;
  }

  return (
    <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.profile}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Tooltip title={`${user?.first_name} ${user?.last_name}`} placement='right-end' >
            <Typography variant="subtitle2" noWrap>
              {user?.first_name}
            </Typography>
          </Tooltip>
          <Tooltip title={handleShowUniv(user?.univ, true)} placement='right-end' >
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {handleShowUniv(user?.univ, false)}
            </Typography>
          </Tooltip>
        </Box>
      </RootStyle>
    </Link>
  );
}
