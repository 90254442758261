import { createSlice } from '@reduxjs/toolkit';
import { InvoiceNew, InvoiceState } from 'types/invoice';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: InvoiceState = {
  isLoadingInvoice: false,
  error: null,
  materies: [],
  materi: null,
  sortBy: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'materi',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingInvoice = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingInvoice = false;
      state.error = action.payload;
    },

    // GET Invoices
    getInvoicesSuccess(state, action) {
      state.isLoadingInvoice = false;
      state.materies = action.payload;
    },

    // GET Invoice
    getInvoiceSuccess(state, action) {
      state.isLoadingInvoice = false;
      state.materi = action.payload;
    },

    //  SORT & FILTER Invoices
    sortByInvoices(state, action) {
      state.sortBy = action.payload;
    },

    filterInvoices(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByInvoices, filterInvoices } = slice.actions;

// ----------------------------------------------------------------------

export function getMateries(role: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let link = '/api/all-invoice-user';
      if (role === 'student') {
        link = '/api/invoice-user';
      }
      const response: { data: { result: InvoiceNew[] } } = await axios.get(link);
      dispatch(slice.actions.getInvoicesSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMateri(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: InvoiceNew } } = await axios.get(
        '/api/invoice-details/' + id
      );
      dispatch(slice.actions.getInvoiceSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
