import { createSlice } from '@reduxjs/toolkit';
import { DetailAnalyticsArticle, AnalyticsArticleState } from 'types/analytics';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: AnalyticsArticleState = {
  isLoadingArticle: false,
  error: null,
  articles: [],
  sortBy: null,
};

const slice = createSlice({
  name: 'analytic_article',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingArticle = true;
    },

    // GET Article
    getUsersSuccess(state, action) {
      state.isLoadingArticle = false;
      state.articles = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingArticle = false;
      state.error = action.payload;
    },

    //  SORT & FILTER Article
    sortByAnalyticArticle(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByAnalyticArticle } = slice.actions;

// ----------------------------------------------------------------------

export function getArticles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: DetailAnalyticsArticle[] } } = await axios.get(
        '/api/analytics/article'
      );
      const temp: number[] = [];
      // eslint-disable-next-line array-callback-return
      await response.data.result.map((data) => {
        temp.push(data.count);
      });
      dispatch(slice.actions.getUsersSuccess(temp));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
