import { createSlice } from '@reduxjs/toolkit';
import { MateriNew, MateriStateNew } from 'types/materi';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: MateriStateNew = {
  isLoading: false,
  error: null,
  materies: [],
  materi: null,
  create: null,
  sortBy: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'materi',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.materi = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Materies
    getMateriesSuccess(state, action) {
      state.isLoading = false;
      state.materies = action.payload;
    },

    // GET Materi
    getMateriSuccess(state, action) {
      state.isLoading = false;
      state.materi = action.payload;
    },

    // Create User
    createUserSuccess(state, action) {
      state.isLoading = false;
      state.create = action.payload;
    },

    // Update User
    updateUserSuccess(state, action) {
      state.isLoading = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoading = false;
      state.create = null;
      state.materies = [];
    },

    //  SORT & FILTER Materies
    sortByMateries(state, action) {
      state.sortBy = action.payload;
    },

    filterMateries(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByMateries, filterMateries } = slice.actions;

// ----------------------------------------------------------------------

export function getMateries() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let link = '/api/materi';
      const response: { data: { result: { data: MateriNew[] } } } = await axios.get(link);
      dispatch(slice.actions.getMateriesSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMateriesOnly() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let link = '/api/only-materi';
      const response: { data: { result: { data: MateriNew[] } } } = await axios.get(link);
      dispatch(slice.actions.getMateriesSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMateri(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: MateriNew } } } = await axios.get(
        `/api/detail-materi/${id}`
      );
      dispatch(slice.actions.getMateriSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createMateri(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/create/materi', payload);
      dispatch(slice.actions.createUserSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateMateri(payload: any, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-materi/${id}`, payload);
      dispatch(slice.actions.updateUserSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMateri(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-materi/${id}`);
      dispatch(slice.actions.updateUserSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetCreateMateri() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getLinkCheckout = async (payload: any) => {
  try {
    let link = `/api/payment/create/invoice`;
    const response: { data: any } = await axios.post(link, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};
