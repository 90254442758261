import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { HOST_API } from 'config';
import Cookies from 'js-cookie';

/**
 * Create axios
 */
const axiosClient = axios.create({
  baseURL: HOST_API,
  headers: {
    Accept: 'application/json',
  },
});

/**
 * Request interceptor. Runs before your request reaches the server
 *
 * @param {any} config
 * @returns {any}
 */
const onRequest = (config: any): any => {
  // If http method is `post | put | delete` and XSRF-TOKEN cookie is
  // Not present, call '/sanctum/csrf-cookie' to set CSRF token, then
  // Proceed with the initial response

  // If token not null, add token to header
  const users = Cookies.get('user');
  if (users !== undefined) {
    // initialize token
    const { token } = JSON.parse(users);
    // add token to header
    config.headers.token = `Bearer|${token}`;
  }

  // If token null, remove token from header
  if (users === undefined) {
    // remove token from header
    delete config.headers.token;
  }

  return config;
};

/**
 * Function for handle error by status
 *
 * @param {number} status
 * @returns {string}
 */
const handleError = (status: number, message: any): string => {
  let errorMessage: string;
  switch (status) {
    case 400:
      errorMessage = message ?? 'Bad request';
      break;
    case 401:
      errorMessage = message ?? 'Token expired';
      break;
    case 403:
      errorMessage = message ?? 'Access denied, you are not authorized to access this page';
      break;
    case 404:
      errorMessage = message ?? 'The requested resource could not be found';
      break;
    case 429:
      errorMessage = message ?? 'Too many requests';
      break;
    case 500:
      errorMessage = message ?? 'Internal Server Error';
      break;
    case 498:
      errorMessage = message ?? 'Token expired';
      break;
    default:
      errorMessage = message ?? 'An error occurred, please try again later';
      break;
  }
  return errorMessage;
};

// attach request
axiosClient.interceptors.request.use(onRequest);

// attach response
axiosClient.interceptors.response.use(
  async (response: AxiosResponse) => response,
  async (error: AxiosError<any>) => {
    // Initialize error message
    if (error?.response?.status) {
      toast.error(handleError(error?.response?.status, error?.response?.data?.message));
    }
    // if unauthorized, redirect to login page
    setTimeout(async () => {
      if (error?.response?.status === 401) {
        Cookies.remove('user', {
          domain: process.env.REACT_APP_DOMAIN,
        });
        window.location.href = '/auth/login';
      }
    }, 2000);

    setTimeout(async () => {
      if (error?.response?.status === 403) {
        window.location.href = '/403';
      }
    }, 100);

    if (error?.response?.status === 413) {
      toast.error('File harus kurang dari 5MB!');
    }

    const response = await Promise.reject(error);

    return response;
  }
);

export default axiosClient;
