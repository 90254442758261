// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import SvgIconStyle from 'components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  perpustakaan: getIcon('perpustakaan'),
  digital_library: getIcon('perpustakaan'),
  testimonial: getIcon('testimonial'),
  team: getIcon('team'),
  articles: getIcon('articles'),
  banner: getIcon('banner'),
  invoice_bill: getIcon('invoice_bill'),
  qoutes: getIcon('qoutes'),
};

export const navadmin = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'kelas digital', path: PATH_DASHBOARD.general.materi, icon: ICONS.invoice },
      { title: 'ukom', path: PATH_DASHBOARD.general.ukom, icon: ICONS.invoice },
      { title: 'kamus', path: PATH_DASHBOARD.general.kamus, icon: ICONS.perpustakaan },
      { title: 'e-journal', path: PATH_DASHBOARD.perpustakaan.list, icon: ICONS.perpustakaan },
      { title: 'perpustakaan digital', path: PATH_DASHBOARD.digitalLibrary.list, icon: ICONS.digital_library },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      { title: 'artikel', path: PATH_DASHBOARD.blog.posts, icon: ICONS.articles },
      { title: 'review buku', path: PATH_DASHBOARD.review, icon: ICONS.testimonial },
      { title: 'testimonial', path: PATH_DASHBOARD.testimonial, icon: ICONS.testimonial },
      { title: 'tim penyusun', path: PATH_DASHBOARD.timPenyusun.root, icon: ICONS.team },
      { title: 'user', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
      { title: 'banner', path: PATH_DASHBOARD.banner.root, icon: ICONS.banner },
      { title: 'qoutes', path: PATH_DASHBOARD.qoutes.root, icon: ICONS.qoutes }
    ],
  },
];

export const navowner = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'kelas digital', path: PATH_DASHBOARD.general.materi, icon: ICONS.invoice },
      { title: 'ukom', path: PATH_DASHBOARD.general.ukom, icon: ICONS.invoice },
      { title: 'kamus', path: PATH_DASHBOARD.general.kamus, icon: ICONS.perpustakaan },
      { title: 'e-journal', path: PATH_DASHBOARD.perpustakaan.list, icon: ICONS.perpustakaan },
      { title: 'perpustakaan digital', path: PATH_DASHBOARD.digitalLibrary.list, icon: ICONS.digital_library },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      { title: 'transaksi', path: PATH_DASHBOARD.transaksi.list, icon: ICONS.analytics },
      { title: 'artikel', path: PATH_DASHBOARD.blog.posts, icon: ICONS.articles },
      { title: 'review buku', path: PATH_DASHBOARD.review, icon: ICONS.testimonial },
      { title: 'testimonial', path: PATH_DASHBOARD.testimonial, icon: ICONS.testimonial },
      { title: 'tim penyusun', path: PATH_DASHBOARD.timPenyusun.root, icon: ICONS.team },
      { title: 'user', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
      { title: 'banner', path: PATH_DASHBOARD.banner.root, icon: ICONS.banner },
      { title: 'qoutes', path: PATH_DASHBOARD.qoutes.root, icon: ICONS.qoutes }
    ],
  },
];

export const navstudents = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'kelas digital', path: PATH_DASHBOARD.general.materi, icon: ICONS.invoice },
      { title: 'perpustakaan digital', path: PATH_DASHBOARD.digitalLibrary.list, icon: ICONS.digital_library },
      { title: 'materi saya', path: PATH_DASHBOARD.materiSaya.root, icon: ICONS.invoice },
      { title: 'e-journal', path: PATH_DASHBOARD.perpustakaan.list, icon: ICONS.perpustakaan },
      { title: 'transaksi', path: PATH_DASHBOARD.transaksi.list, icon: ICONS.analytics },
    ],
  },
];
