import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// type
import { UploadSinglePdfProps } from './type';
//
import RejectionFiles from './RejectionFiles';
import BlockContentPdf from './BlockContentPdf';
import PdfPreview from './PdfPreview';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export default function UploadSinglePdf({
  error = false,
  file,
  onRemove,
  helperText,
  sx,
  ...other
}: UploadSinglePdfProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: '80%', ...sx }}>
      {!file &&  <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContentPdf />
      </DropZoneStyle>}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {file && <PdfPreview file={file} onRemove={onRemove} />}

      {helperText && helperText}
    </Box>
  );
}
