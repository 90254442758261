import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  const reverse: string = number.toString().split('').reverse().join('');
  let ribuan: any = reverse.match(/\d{1,3}/g);
  const result = `Rp. ${ribuan.join('.').split('').reverse().join('')}`;
  return result;

  // return numeral.locale(temp);
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0 b');
}
