import { createContext, ReactNode, useEffect, useReducer } from 'react';
import Cookies from 'js-cookie';
// utils
import axios from 'utils/axios';
import { setSession } from 'utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from 'types/auth';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const users = Cookies.get('user');

        if (users) {
          const { token, user } = JSON.parse(users);
          setSession(token, user, true);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: user ? user : '',
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string, remember: boolean, setError: any) => {
    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.post(API_URL + '/api/login', {
        email,
        password,
      });
      if (response?.data?.status === 'success') {
        const { accessToken, user } = response.data.result;
        await setSession(accessToken, user, remember);
        dispatch({
          type: Types.Login,
          payload: {
            user,
          },
        });
      }
      if (response?.data?.status === 'failed') {
        setError('afterSubmit', { message: response.data.message });
      }
    } catch (error) {
      return error;
    }
  };

  const register = async (
    firstName: string,
    lastName: string,
    university: string,
    faculty: string,
    email: string,
    password: string,
    phone_number: string
  ) => {
    const API_URL = process.env.REACT_APP_API_URL;

    const response = await axios.post(API_URL + '/api/register/student', {
      first_name: firstName,
      last_name: lastName,
      university,
      faculty,
      email,
      password,
      phone_number,
    });

    const { accessToken, user } = response.data.result;

    await setSession(accessToken, user);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
    return response;
  };

  const logout = async () => {
    setSession(null, null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
