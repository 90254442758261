import { combineReducers } from 'redux';

import analyticUserReducer from './slices/analytics/user';
import analyticArticleReducer from './slices/analytics/articles';
import analyticTransactionReducer from './slices/analytics/transactions';
import transactionsReducer from './slices/transactions';
import materiReducer from './slices/materi';
import ukomReducer from './slices/ukom';
import materiUserReducer from './slices/materiUser';
import libraryReducer from './slices/perpustakaan';
import articlesReducer from './slices/article';
import teamsReducer from './slices/team';
import usersReducer from './slices/users';
import bannersReducer from './slices/banners';
import testiReducer from './slices/testimonial';
import kamusReducer from './slices/kamus';
import EbooksReducer from './slices/digitalLibrary';
import EbooksUserReducer from './slices/digitalLibraryUser';
import ReviewsEbooks from './slices/reviews';
import QoutesReducer from './slices/qoutes';

const rootReducer = combineReducers({
  analytic_user: analyticUserReducer,
  analytic_transaction: analyticTransactionReducer,
  analytic_article: analyticArticleReducer,
  transactions: transactionsReducer,
  materi: materiReducer,
  ukom: ukomReducer,
  materi_user: materiUserReducer,
  kamus: kamusReducer,

  librarys: libraryReducer,
  articles: articlesReducer,
  teams: teamsReducer,
  users: usersReducer,
  banner: bannersReducer,
  testimonials: testiReducer,
  ebooks: EbooksReducer,
  ebooks_user: EbooksUserReducer,
  reviews: ReviewsEbooks,
  qoutes: QoutesReducer
});

export { rootReducer };
