import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
// guards
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
// import RoleBasedGuard from 'guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from 'config';
// components
import LoadingScreen from 'components/LoadingScreen';
import Cookies from 'js-cookie';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const users = Cookies.get('user');
  const role = users !== undefined ? JSON.parse(users).user.role : '';

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'new-password', element: <NewPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={role === "admin" || role === "owner" ? PATH_AFTER_LOGIN : "/dashboard/materi/list"} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'materi',
          children: [
            { element: <Navigate to="/dashboard/materi/list" replace />, index: true },
            { path: 'new', element: <MateriCreate /> },
            { path: 'list', element: <MateriList /> },
            { path: 'details/:id', element: <MateriDetails /> },
            { path: 'edit/:id', element: <MateriEdit /> },
          ]
        },
        {
          path: 'ukom',
          children: [
            { element: <Navigate to="/dashboard/ukom/list" replace />, index: true },
            { path: 'new/kuesioner', element: <UkomCreateKuesioner /> },
            { path: 'new/essay', element: <UkomCreateEssay /> },
            { path: 'list', element: <UkomList /> },
            { path: 'details/:id', element: <UkomDetails /> },
            { path: 'edit/kuesioner/:id', element: <UkomEditKuesioner /> },
            { path: 'edit/essay/:id', element: <UkomEditEssay /> },
          ]
        },
        {
          path: 'kamus',
          children: [
            { element: <Navigate to="/dashboard/kamus/list" replace />, index: true },
            { path: 'new', element: <KamusCreate /> },
            { path: 'list', element: <KamusList /> },
            { path: 'details/:id', element: <KamusDetails /> },
            { path: 'edit/:id', element: <KamusEdit /> },
          ]
        },
        {
          path: 'kelas-saya',
          children: [
            { element: <Navigate to="/dashboard/kelas-saya/list" replace />, index: true },
            { path: 'list', element: <MateriUserList /> },
            { path: 'list-bab/:title', element: <MateriBabList /> },
            { path: 'kamus-details/:id', element: <KamusDetailsKelasUser /> },
            { path: 'ebook-details/:id', element: <EbookDetailsKelasUser /> },
          ]
        },
        {
          path: 'qoutes',
          children: [
            { element: <Navigate to="/dashboard/qoutes/list" replace />, index: true },
            { path: 'new', element: <QoutesCreate /> },
            { path: 'list', element: <QoutesList /> },
            { path: 'edit/:id', element: <QoutesEdit /> },
          ]
        },
        {
          path: 'perpustakaan',
          children: [
            { element: <Navigate to="/dashboard/perpustakaan/list" replace />, index: true },
            { path: 'new', element: <PerpustakaanCreate /> },
            { path: 'list', element: <PerpustakaanList /> },
            { path: 'edit/:id', element: <PerpustakaanEdit /> },
          ]
        },
        {
          path: 'digital-library',
          children: [
            { element: <Navigate to="/dashboard/digital-library/list" replace />, index: true },
            { path: 'new', element: <EbookCreate /> },
            { path: 'list', element: <EbookList /> },
            { path: 'view/:id', element: <EbookView /> },
            { path: 'edit/:id', element: <EbookEdit /> },
          ]
        },
        { path: 'reviews-books', element: <ReviewBooks /> },
        { path: 'testimonial', element: <Testimonial /> },
        {
          path: 'banner',
          children: [
            { element: <Navigate to="/dashboard/banner/list" replace />, index: true },
            { path: 'new', element: <BannerCreate /> },
            { path: 'list', element: <BannerList /> },
            { path: 'edit/:id', element: <BannerEdit /> },
          ]
        },
        {
          path: 'tim-penyusun',
          children: [
            { element: <Navigate to="/dashboard/tim-penyusun/list" replace />, index: true },
            { path: 'list', element: <TimPenyusun /> },
            { path: 'new', element: <TimPenyusunCreate /> },
            { path: 'edit/:id', element: <TimPenyusunEdit /> },
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'profile', element: <UserAccount /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserEdit /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/dashboard/transactions/list" replace />, index: true },
            { path: 'list', element: <TransactionList /> },
            { path: ':id', element: <TransactionsDetails /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title/:id', element: <BlogEditPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    // Route Play Video Materi
    { path: 'list-play/:title', element: <MateriPlayList /> },
    { path: 'ukom-work/:id', element: <UkomUserWork /> },
    { path: 'ukom-discussion/:id', element: <UkomUserDiscussion /> },
    
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const Register = Loadable(lazy(() => import('pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
// const NewPassword = Loadable(lazy(() => import('pages/auth/NewPassword')));
// const VerifyCode = Loadable(lazy(() => import('pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('pages/dashboard/Analytics')));

const MateriList = Loadable(lazy(() => import('pages/dashboard/Materi/MateriList')));
const MateriDetails = Loadable(lazy(() => import('pages/dashboard/Materi/MateriDetails')));
const MateriCreate = Loadable(lazy(() => import('pages/dashboard/Materi/MateriCreate')));
const MateriEdit = Loadable(lazy(() => import('pages/dashboard/Materi/MateriEdit')));

const MateriUserList = Loadable(lazy(() => import('pages/dashboard/KelasSaya/Materi')));
const MateriBabList = Loadable(lazy(() => import('pages/dashboard/KelasSaya/MateriDetails')));
const MateriPlayList = Loadable(lazy(() => import('pages/dashboard/KelasSaya/MateriPlayList')));
const KamusDetailsKelasUser = Loadable(lazy(() => import('pages/dashboard/KelasSaya/KamusDetailsUser')));
const EbookDetailsKelasUser = Loadable(lazy(() => import('pages/dashboard/KelasSaya/EbookDetails')));

const UkomList = Loadable(lazy(() => import('pages/dashboard/Ukom/List/UkomList')));
const UkomDetails = Loadable(lazy(() => import('pages/dashboard/Ukom/List/UkomDetails')));
const UkomCreateKuesioner = Loadable(lazy(() => import('pages/dashboard/Ukom/List/Create/UkomCreateKuesioner')));
const UkomCreateEssay = Loadable(lazy(() => import('pages/dashboard/Ukom/List/Create/UkomCreateEssay')));
const UkomEditKuesioner = Loadable(lazy(() => import('pages/dashboard/Ukom/List/Edit/UkomEditKuesioner')));
const UkomEditEssay = Loadable(lazy(() => import('pages/dashboard/Ukom/List/Edit/UkomEditEssay')));

const UkomUserWork = Loadable(lazy(() => import('pages/dashboard/Ukom/Users/UkomWork')));
const UkomUserDiscussion = Loadable(lazy(() => import('pages/dashboard/Ukom/Users/UkomDiscussion')));

const KamusList = Loadable(lazy(() => import('pages/dashboard/Kamus/KamusList')));
const KamusDetails = Loadable(lazy(() => import('pages/dashboard/Kamus/KamusDetails')));
const KamusCreate = Loadable(lazy(() => import('pages/dashboard/Kamus/KamusCreate')));
const KamusEdit = Loadable(lazy(() => import('pages/dashboard/Kamus/KamusEdit')));

// const UkomUserList = Loadable(lazy(() => import('pages/dashboard/Ukom/Users/Ukom')));
// const UkomView = Loadable(lazy(() => import('pages/dashboard/Ukom/Users/UkomView')));

const PerpustakaanList = Loadable(lazy(() => import('pages/dashboard/Perpustakaan/PerpustakaanList')));
const PerpustakaanCreate = Loadable(lazy(() => import('pages/dashboard/Perpustakaan/PerpustakaanCreate')));
const PerpustakaanEdit = Loadable(lazy(() => import('pages/dashboard/Perpustakaan/PerpustakaanEdit')));

const QoutesList = Loadable(lazy(() => import('pages/dashboard/Qoutes/QuotesList')));
const QoutesCreate = Loadable(lazy(() => import('pages/dashboard/Qoutes/QuotesCreate')));
const QoutesEdit = Loadable(lazy(() => import('pages/dashboard/Qoutes/QuotesEdit')));

const EbookList = Loadable(lazy(() => import('pages/dashboard/DigitalLibrary/List')));
const EbookCreate = Loadable(lazy(() => import('pages/dashboard/DigitalLibrary/Create')));
const EbookEdit = Loadable(lazy(() => import('pages/dashboard/DigitalLibrary/Edit')));
const EbookView = Loadable(lazy(() => import('pages/dashboard/DigitalLibrary/Details')));

const TimPenyusun = Loadable(lazy(() => import('pages/dashboard/Teams/TimPenyusun')));
const TimPenyusunCreate = Loadable(lazy(() => import('pages/dashboard/Teams/TimPenyusunCreate')));
const TimPenyusunEdit = Loadable(lazy(() => import('pages/dashboard/Teams/TimPenyusunEdit')));

const BannerList = Loadable(lazy(() => import('pages/dashboard/Banners/BannerList')));
const BannerCreate = Loadable(lazy(() => import('pages/dashboard/Banners/BannerCreate')));
const BannerEdit = Loadable(lazy(() => import('pages/dashboard/Banners/BannerEdit')));

// Transaction
const TransactionList = Loadable(lazy(() => import('pages/dashboard/Transactions')));
const TransactionsDetails = Loadable(lazy(() => import('pages/dashboard/Transactions/TransactionsDetails')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('pages/dashboard/Article/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('pages/dashboard/Article/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('pages/dashboard/Article/BlogNewPost')));
const BlogEditPost = Loadable(lazy(() => import('pages/dashboard/Article/BlogEditPost')));

// ReviewBooks
const ReviewBooks = Loadable(lazy(() => import('pages/dashboard/ReviewBooks')));
// Testimonial
const Testimonial = Loadable(lazy(() => import('pages/dashboard/Testimonial')));

// USER
const UserList = Loadable(lazy(() => import('pages/dashboard/Users/UserList')));
const UserAccount = Loadable(lazy(() => import('pages/dashboard/Users/UserAccount')));
const UserCreate = Loadable(lazy(() => import('pages/dashboard/Users/UserCreate')));
// const UserEdit = Loadable(lazy(() => import('pages/dashboard/Users/UserEdit')));
const UserEdit = Loadable(lazy(() => import('pages/dashboard/Users/UserEditNew')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('pages/dashboard/PermissionDenied')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('pages/helpers/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('pages/helpers/Maintenance')));
// const Pricing = Loadable(lazy(() => import('pages/helpers/Pricing')));
// const Payment = Loadable(lazy(() => import('pages/helpers/Payment')));
const Page500 = Loadable(lazy(() => import('pages/helpers/Page500')));
const Page403 = Loadable(lazy(() => import('pages/helpers/Page403')));
const Page404 = Loadable(lazy(() => import('pages/helpers/Page404')));
