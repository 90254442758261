import { createSlice } from '@reduxjs/toolkit';
import { TeamState, Team } from 'types/teams';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: TeamState = {
  isLoadingTeam: false,
  error: null,
  teams: [],
  team: null,
  sortBy: null,
  create: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingTeam = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingTeam = false;
      state.error = action.payload;
    },

    // GET Teams
    getTeamsSuccess(state, action) {
      state.isLoadingTeam = false;
      state.teams = action.payload;
    },

    // GET Team
    getTeamSuccess(state, action) {
      state.isLoadingTeam = false;
      state.team = action.payload;
    },

    // Create Team
    createTeamSuccess(state, action) {
      state.isLoadingTeam = false;
      state.create = action.payload;
    },

    // Update Team
    updateTeamSuccess(state, action) {
      state.isLoadingTeam = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoadingTeam = false;
      state.create = null;
    },

    //  SORT & FILTER Team
    sortByTeam(state, action) {
      state.sortBy = action.payload;
    },

    filterTeam(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByTeam, filterTeam } = slice.actions;

// ----------------------------------------------------------------------

export function getTeams() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Team[] } } } = await axios.get('/api/teams');
      dispatch(slice.actions.getTeamsSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTeam(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Team } } } = await axios.get(
        '/api/detail-teams/' + id
      );
      dispatch(slice.actions.getTeamSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTeam(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/create-teams', payload);
      dispatch(slice.actions.createTeamSuccess(response.data.message));
      return response.data.message
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTeam(payload: any, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-teams/${id}`, payload);
      dispatch(slice.actions.updateTeamSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTeam(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-teams/${id}`);
      dispatch(slice.actions.updateTeamSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetCreateTeam() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
