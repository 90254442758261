// hooks
import useAuth from 'hooks/useAuth';
// utils
import createAvatar from 'utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';
import { HOST_API } from 'config';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={`${HOST_API}/${user ? user.image : ''}`}
      alt={user?.name}
      color={user?.image ? 'default' : createAvatar(user?.name).color}
      {...other}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  );
}
