import { m, AnimatePresence } from 'framer-motion';
// @mui
import { List, IconButton, ListItemText, ListItem } from '@mui/material';
// utils
import { fData } from 'utils/formatNumber';
// type
import { UploadSinglePdfProps } from './type';
//
import Iconify from 'components/Iconify';
import { varFade } from 'components/animate';
import { HOST_API } from 'config';
// ----------------------------------------------------------------------

export default function PdfPreview({
  file,
  onRemove,
}: UploadSinglePdfProps) {
    const handleViewPdf = () => {
        if (typeof file === 'string') {
            window.open(`${HOST_API}/${file}`, '_blank');
        }
        if (typeof file !== 'string') {
            window.open(file.preview, '_blank');
        }
    }
    return (
        <List disablePadding>
            <AnimatePresence>
                {
                    file && (
                        <ListItem
                            component={m.div}
                            {...varFade().inRight}
                            sx={{
                                px: 2,
                                py: 0.1,
                                borderRadius: 0.75,
                                border: (theme) => `solid 1px ${theme.palette.divider}`,
                            }}
                        >
                            <Iconify
                                icon={'eva:file-fill'}
                                sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }}
                            />

                            <ListItemText
                                onClick={handleViewPdf}
                                primary={typeof file === 'string' ? file.substring(file.lastIndexOf("/") + 1) : file.name}
                                secondary={typeof file === 'string' ? '-' : fData(file.size || 0)}
                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                secondaryTypographyProps={{ variant: 'caption' }}
                                sx={{
                                    cursor: 'pointer'
                                }}
                            />

                            {onRemove && (
                                <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                                    <Iconify icon={'eva:close-fill'} />
                                </IconButton>
                            )}
                        </ListItem>
                    )
                }
            </AnimatePresence>
        </List>
    );
}
