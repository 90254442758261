import { createSlice } from '@reduxjs/toolkit';
import { Qoutes, QoutesState, CreateQoutes } from 'types/qoutes';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: QoutesState = {
  isLoadingQoutes: false,
  error: null,
  qoutes: [],
  qoute: null,
  sortBy: null,
  create: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'qoutes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingQoutes = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingQoutes = false;
      state.error = action.payload;
    },

    // GET Qoutes
    getQoutesSuccess(state, action) {
      state.isLoadingQoutes = false;
      state.qoutes = action.payload;
    },

    // GET Qoute
    getQouteSuccess(state, action) {
      state.isLoadingQoutes = false;
      state.qoute = action.payload;
    },

    // Create Qoute
    createQoutesSuccess(state, action) {
      state.isLoadingQoutes = false;
      state.create = action.payload;
    },

    // Update Qoute
    updateQoutesSuccess(state, action) {
      state.isLoadingQoutes = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoadingQoutes = false;
      state.create = null;
    },

    //  SORT & FILTER Qoutes
    sortByQoutes(state, action) {
      state.sortBy = action.payload;
    },

    filterQoutes(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByQoutes, filterQoutes } = slice.actions;

// ----------------------------------------------------------------------

export function getQoutes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: Qoutes[] } } = await axios.get('/api/qoutes');
      dispatch(slice.actions.getQoutesSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getQoute(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Qoutes } } } = await axios.get(
        '/api/detail-qoutes/' + id
      );
      dispatch(slice.actions.getQouteSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createQoutes(payload: CreateQoutes) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/create-qoutes', payload);
      dispatch(slice.actions.createQoutesSuccess(response.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateQoutes(payload: CreateQoutes, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-qoutes/${id}`, payload);
      dispatch(slice.actions.updateQoutesSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteQoute(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-qoutes/${id}`);
      dispatch(slice.actions.updateQoutesSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetreateQoutes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
