// routes
import Router from './routes';
import numeral from 'numeral';
// theme
import ThemeProvider from './theme';
// components
// import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';

// ----------------------------------------------------------------------

export default function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  (function () {
    numeral.register('locale', 'idn', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'rb',
        million: 'jt',
        billion: 'm',
        trillion: 't',
      },
      ordinal: function (number) {
        return '.';
      },
      currency: {
        symbol: 'Rp.',
      },
    });
  });

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
      </ThemeProvider>
      <ToastContainer />
    </MotionLazyContainer>
  );
}
