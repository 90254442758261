import { createSlice } from '@reduxjs/toolkit';
import { ReviewsState, Review } from 'types/reviews';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: ReviewsState = {
  isLoading: false,
  error: null,
  reviews: [],
};

const slice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Teams
    getReviewsSuccess(state, action) {
      state.isLoading = false;
      state.reviews = action.payload;
    },

    // Update Team
    updateTestiSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReviews() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Review[] } } } = await axios.get(
        '/api/all/review-ebooks'
      );
      dispatch(slice.actions.getReviewsSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function deleteReview(id: string) {
  try {
    const response: {
      data: {
        message: string;
      };
    } = await axios.delete(`/api/review-ebooks/${id}`);
    return response.data.message;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error.message;
  }
}

// ----------------------------------------------------------------------
