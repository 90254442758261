import { createSlice } from '@reduxjs/toolkit';
import { AnswersType, QuestionsType, UkomNew, UkomStateNew } from 'types/ukom';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: UkomStateNew = {
  isLoading: false,
  error: null,
  ukom: [],
  ukom_details: null,
  ukom_details_preview: null,
  ukom_details_work: null,
  question_details: null,
  answers: null,
  create: null,
  sortBy: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'ukom',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Ukoms
    getUkomsSuccess(state, action) {
      state.isLoading = false;
      state.ukom = action.payload;
    },

    // GET Ukom Details For Work
    getUkomDetailsFowWorkSuccess(state, action) {
      state.isLoading = false;
      state.ukom_details_work = action.payload;
    },

    // GET Ukom Details
    getUkomDetailsReset(state) {
      state.isLoading = true;
      state.ukom_details = null;
    },

    // GET Ukom Details
    getUkomDetailsSuccess(state, action) {
      state.isLoading = false;
      state.ukom_details = action.payload;
    },

    // GET Details Question
    getQuestionDetailsSuccess(state, action) {
      state.isLoading = false;
      state.question_details = action.payload;
    },

    // Get details ukom preview
    getUkomDetailsPreviewSuccess(state, action) {
      state.isLoading = false;
      state.ukom_details_preview = action.payload;
    },

    // Get answers user
    getAnswersSuccess(state, action) {
      state.isLoading = false;
      state.answers = action.payload;
    },

    // Create Ukom
    createUkomSuccess(state, action) {
      state.isLoading = false;
      state.create = action.payload;
    },

    // Update Ukom
    updateUkomSuccess(state, action) {
      state.isLoading = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoading = false;
      state.create = null;
      state.ukom = [];
    },

    //  SORT & FILTER Materies
    sortByMateries(state, action) {
      state.sortBy = action.payload;
    },

    filterMateries(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByMateries, filterMateries } = slice.actions;

// ----------------------------------------------------------------------

export function getUkoms() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let link = '/api/ukom';
      const response: { data: { result: { data: UkomNew[] } } } = await axios.get(link);
      dispatch(slice.actions.getUkomsSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUkomDetailsForUpdate(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: UkomNew } } } = await axios.get(
        `/api/detail-update-ukom/${id}`
      );
      dispatch(slice.actions.getUkomDetailsSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUkomDetails(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: UkomNew } } } = await axios.get(
        `/api/detail-ukom-preview/${id}`
      );
      dispatch(slice.actions.getUkomDetailsPreviewSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUkomDetailsForWork(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: UkomNew[] } } = await axios.get(
        `/api/detail-ukom-worked/${id}`
      );
      dispatch(slice.actions.getUkomDetailsFowWorkSuccess(response.data.result[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQuestionDetails(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: QuestionsType[] } } } = await axios.get(
        `/api/detail-question/${id}`
      );
      dispatch(slice.actions.getQuestionDetailsSuccess(response.data.result.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQuestionDetailsWithAnswer(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: QuestionsType[] } } } = await axios.get(
        `/api/detail-question-with-answer/${id}`
      );
      dispatch(slice.actions.getQuestionDetailsSuccess(response.data.result.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAnswersUser(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: AnswersType[] } } = await axios.get(
        `/api/all-answer-user/${id}`
      );
      dispatch(slice.actions.getAnswersSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUkom(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/create/ukom', payload);
      dispatch(slice.actions.createUkomSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUkomEssay(payload: any, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-ukom-essay/${id}`, payload);
      dispatch(slice.actions.updateUkomSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function updateUkomKuesioner(payload: any, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-ukom-kuesioner/${id}`, payload);
      dispatch(slice.actions.updateUkomSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUkom(id: string) {
  return async () => {
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-ukom/${id}`);
      return response.data.message;
    } catch (error) {
      return error.message;
    }
  };
}

export function answerUser(payload: any) {
  return async () => {
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/answer-user', payload);
      return response.data.message;
    } catch (error) {
      return error.message;
    }
  };
}

export function finishUkom(payload: any) {
  return async () => {
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/update-ukom-user', payload);
      return response.data.message;
    } catch (error) {
      return error.message;
    }
  };
}

export function resetUkom(id: any) {
  return async () => {
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post(`/api/restart-ukom-user/${id}`);
      return response.data.message;
    } catch (error) {
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetCreateUkom() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
