import { createSlice } from '@reduxjs/toolkit';
import { ArticleState, Article } from 'types/blog';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: ArticleState = {
  isLoadingArticle: false,
  error: null,
  articles: [],
  article: null,
  sortBy: null,
  create: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'materi',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingArticle = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingArticle = false;
      state.error = action.payload;
    },

    // GET Article
    getArticlesSuccess(state, action) {
      state.isLoadingArticle = false;
      state.articles = action.payload;
      state.article = null;
    },

    // GET Article
    getArticleSuccess(state, action) {
      state.isLoadingArticle = false;
      state.article = action.payload;
    },

    // Create Article
    createArticleSuccess(state, action) {
      state.isLoadingArticle = false;
      state.create = action.payload;
    },

    // Update Article
    updateArticleSuccess(state, action) {
      state.isLoadingArticle = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoadingArticle = false;
      state.create = null;
    },

    //  SORT & FILTER Article
    sortByLibrary(state, action) {
      state.sortBy = action.payload;
    },

    filterLibrary(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByLibrary, filterLibrary } = slice.actions;

// ----------------------------------------------------------------------

export function getArticles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: Article[] } } = await axios.get('/api/article');
      dispatch(slice.actions.getArticlesSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getArticle(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Article } } } = await axios.get(
        `/api/detail-article/${id}`
      );
      dispatch(slice.actions.getArticleSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createArticle(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/create-article', payload);
      dispatch(slice.actions.createArticleSuccess(response.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateArticle(payload: any, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-article/${id}`, payload);
      dispatch(slice.actions.updateArticleSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteArticle(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-article/${id}`);
      dispatch(slice.actions.updateArticleSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetreateArticle() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
