import { createSlice } from '@reduxjs/toolkit';
import {
  Materi,
  MateriUserState,
  MateriDetail,
  AddMateriUser,
  AddUkomUser,
} from 'types/materi_user';
// utils
import axioss from 'utils/axios';
//
import { dispatch } from 'redux/store';
import { HOST_API } from 'config';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const initialState: MateriUserState = {
  isLoading: false,
  error: null,
  materies: [],
  materi: null,
  materi_bab: null,
  sortBy: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'materi_users',
  initialState,
  reducers: {
    // Reset
    resetDataMateriBab(state) {
      state.isLoading = false;
      state.materi_bab = null;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Materies
    getMateriesSuccess(state, action) {
      state.isLoading = false;
      state.materies = action.payload;
    },

    // GET Materi Detail
    getMateriDetailSuccess(state, action) {
      state.isLoading = false;
      state.materi = action.payload.data;
    },

    // GET Materi Bab Detail
    getMateriBabDetailSuccess(state, action) {
      state.isLoading = false;
      state.materi_bab = action.payload.data;
    },

    addMateriDetailSuccess(state, action) {
      state.isLoading = false;
    },

    //  SORT & FILTER Materies
    sortByMateries(state, action) {
      state.sortBy = action.payload;
    },

    filterMateries(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByMateries, filterMateries } = slice.actions;

// ----------------------------------------------------------------------

export function getMateries(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let link = '/api/materi-user';

      if (id) {
        link = '/api/allmateri-user-details/' + id;
      }

      const response: { data: { result: Materi[] } } = await axioss.get(link);
      dispatch(slice.actions.getMateriesSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMateriDetails(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let link = `/api/materi-user-details/${name}`;
      const response: { data: { result: MateriDetail[] } } = await axioss.get(link);
      dispatch(slice.actions.getMateriDetailSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMateriBabDetails(name: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let link = `/api/detail-bab/${name}`;
      const response: { data: { result: MateriDetail[] } } = await axioss.get(link);
      dispatch(slice.actions.getMateriBabDetailSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function downloadPdf(name: string) {
  return async () => {
    try {
      window.open(`${HOST_API}/api/download-pdf/${name}`, '_blank');
      return;
    } catch (error) {
      toast.error(error.message);
      return error.message;
    }
  };
}

export function submitAddMateriManual(payload: AddMateriUser) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axioss.post(`/api/add-materi-user`, payload);

      dispatch(slice.actions.addMateriDetailSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

export function submitAddUkomManual(payload: AddUkomUser) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axioss.post(`/api/add-ukom-user`, payload);

      dispatch(slice.actions.addMateriDetailSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

export function deleteMateriUserActive(id: string, id_student: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axioss.delete(`/api/delete-materi-user/${id}/${id_student}`);
      dispatch(slice.actions.addMateriDetailSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

export function deleteUkomUserActive(id: string, id_student: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axioss.delete(`/api/delete-ukom-user/${id}/${id_student}`);
      dispatch(slice.actions.addMateriDetailSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

export function submitReview(payload: { testimoni: string }) {
  return async () => {
    try {
      let link = `/api/create-testimoni`;
      const response: { data: { message: MateriDetail[] } } = await axioss.post(link, payload);
      toast.success(response.data.message);
      return true;
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };
}

// ----------------------------------------------------------------------

export function resetMateriBab() {
  return async () => {
    dispatch(slice.actions.resetDataMateriBab());
  };
}

