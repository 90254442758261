// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
// type
import {
  UploadAvatar,
  UploadMultiFile,
  UploadSingleFile,
  UploadProps,
  UploadMultiFileProps,
  UploadSinglePdf,
  UploadSinglePdfProps,
} from 'components/upload';
import UploadSingleFileAudio from '../upload/UploadSingleFileAudio';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
  name: string;
}

export function RHFUploadAvatar({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar error={checkError} {...other} file={field.value} />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUploadSingleFile({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            accept="image/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------
interface RHFUploadPdfFileProps extends Omit<UploadSinglePdfProps, 'file'> {
  name: string;
  clearErrors?: any;
}
export function RHFUploadSingleFileAudio({ clearErrors, name, ...other }: RHFUploadPdfFileProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        const handleDropAudio = (acceptedFiles: any) => {
          const file = acceptedFiles[0];
          if (file) {
            clearErrors(name)
            field.onChange(Object.assign(file, {
              preview: URL.createObjectURL(file),
            }))
          }
        }
        const handleRemove = () => {
          field.onChange('');
        };

        return (
          <UploadSingleFileAudio
            accept="audio/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            onDrop={(e) => handleDropAudio(e)}
            onRemove={() => handleRemove()}
            {...other}
          />
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFUploadMultiFileProps extends Omit<UploadMultiFileProps, 'files'> {
  name: string;
}

export function RHFUploadMultiFile({ name, ...other }: RHFUploadMultiFileProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            accept="image/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------
interface RHFUploadPdfFileProps extends Omit<UploadSinglePdfProps, 'file'> {
  name: string;
  clearErrors?: any;
}

export function RHFUploadSingleFilePdf({ clearErrors, name, ...other }: RHFUploadPdfFileProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        const handleDropPdf = (acceptedFiles: any) => {
          const file = acceptedFiles[0];
    
          if (file) {
            clearErrors(name)
            field.onChange(Object.assign(file, {
              preview: URL.createObjectURL(file),
            }))
          }
        }
        const handleRemove = () => {
          field.onChange('');
        };
        return (
          <UploadSinglePdf
            accept="application/pdf"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            onDrop={(e) => handleDropPdf(e)}
            onRemove={() => handleRemove()}
            {...other}
          />
        );
      }}
    />
  );
}

export function RHFUploadSingleFileIcon({ clearErrors, name, ...other }: RHFUploadPdfFileProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        const handleDropFile = (acceptedFiles: any) => {
          const file = acceptedFiles[0];
    
          if (file) {
            clearErrors(name)
            field.onChange(Object.assign(file, {
              preview: URL.createObjectURL(file),
            }))
          }
        }
        const handleRemoveFile = () => {
          field.onChange('');
        };
        return (
          <UploadSingleFile
            accept="image/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            onDrop={(e) => handleDropFile(e)}
            onRemove={() => handleRemoveFile()}
            {...other}
          />
        );
      }}
    />
  );
}
