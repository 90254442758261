import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, IconButton, InputAdornment, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Iconify from 'components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from 'components/hook-form';
import SocialsButton from 'components/SocialsButton';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  useEffect(() => {
    const token = query.get('secret');
    if (token) {
      handleLoginGoogle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleLoginGoogle = async () => {
    const token = query.get('secret');
    const user = {
      first_name: query.get('first') ? query.get('first') : '',
      last_name: query.get('last') ? query.get('last') : '',
      image: query.get('image') ? query.get('image') : '',
      role: query.get('role') ? query.get('role') : '',
      univ: query.get('univ') ? query.get('univ') : '',
    };
    if (token && user) {
      const session = {
        token,
        user,
      };
      Cookies.set('user', JSON.stringify(session), {
        domain: process.env.REACT_APP_DOMAIN,
        expires: 30,
      });

      if (user.role === 'admin' || user.role === 'owner') {
        window.location.replace('/dashboard/app');
      } else {
        window.location.replace('/dashboard/materi/list');
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email tidak valid")
      .required("Email harus diisi"),
    password: Yup.string().required("Password harus diisi"),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    await login(data.email, data.password, data.remember, setError);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{
          color: '#0F7CA1',
          bgcolor: '#EEEB4B',
          ':hover': {
            bgcolor: '#259E3F',
            color: '#ffffff',
          },
        }}
      >
        Masuk
      </LoadingButton>

      <Divider sx={{ mt: 5 }}>OR</Divider>

      <Stack alignItems="center">
        <SocialsButton
          initialColor
          sx={{ my: 2.5 }}
          links={{
            google: `${process.env.REACT_APP_API_URL}/api/login/google`,
          }}
        />
      </Stack>
    </FormProvider>
  );
}
