import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography, IconButton } from '@mui/material';
// type
import { UploadProps } from './type';
// Audio
import { AudioPlayer } from 'react-audio-play';
//
import RejectionFiles from './RejectionFiles';
import { HOST_API } from 'config';
import { UploadIllustration } from 'assets';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export default function UploadSingleFileAudio({
  error = false,
  file,
  helperText,
  sx,
  onRemove,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {!file && (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter',
            }),
            ...(file && {
              padding: '50% 0',
            }),
          }}
        >
          <input {...getInputProps()} />

          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={{ xs: 'column', md: 'row' }}
            sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
          >
            <UploadIllustration sx={{ width: 220 }} />

            <Box sx={{ p: 3 }}>
              <Typography gutterBottom variant="h5">
                Drop or Select file
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Allowed *.mp3, *.wav
              </Typography>
            </Box>
          </Stack>
        </DropZoneStyle>
      )}
      {file && typeof file === 'string' ? (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '12px' }}>
          <AudioPlayer
            src={`${HOST_API}/${file}`}
            style={{
              width: '100%',
            }}
          />
          {onRemove && (
            <IconButton edge="end" size="small" onClick={() => onRemove(file)} sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
              X
            </IconButton>
          )}
        </div>
      ) : file !== undefined && file !== null && typeof file !== 'string' && file.preview !== undefined && (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '12px' }}>
          <AudioPlayer
            src={file.preview}
            style={{
              width: '100%',
            }}
          />
          {onRemove && (
            <IconButton edge="end" size="small" onClick={() => onRemove(file)} sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
              X
            </IconButton>
          )}
        </div>
      )}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
