import { Link as RouterLink } from 'react-router-dom';
// @mui
import { BoxProps } from '@mui/material';
import { AlterLogo } from 'assets';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = <AlterLogo sx={{ width: 100, height: 60, ...sx }} />;

  if (disabledLink) {
    return <a href='https://sahabatalter.com'>{logo}</a>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
