// form
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Form } from "react-bootstrap";

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

type IPropsRegister = {
    name: string;
    label: string;
    type: string;
    placeholder: string;
    style?: any;
}

export default function RHFTextFieldRegister({ name, label, type, placeholder, style }: IPropsRegister) {
    const { control } = useFormContext();
    const { errors } = useFormState({
        control
    })

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { ref, value, onChange }
            }) => (
                <div className="d-flex flex-column" style={style}>
                    <LabelStyle>{label}</LabelStyle>
                    <Form.Control
                        ref={ref}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        style={{
                            padding: "10px",
                            borderRadius: "5px",
                            outline: 'none',
                            border: '1px solid rgb(220, 224, 228)',
                            marginBottom: "5px"
                        }}
                    />
                    <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }) => (
                            <small
                                style={{
                                    color: 'red',
                                    fontSize: "12px",
                                    padding: 0,
                                    margin: 0,
                                }}
                            >{message}</small>
                        )}
                    />
                </div>
            )}
        />
    );
}
