import { createSlice } from '@reduxjs/toolkit';
import { Library, LibraryState, CreateLibrary } from 'types/library';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: LibraryState = {
  isLoadingLibrary: false,
  error: null,
  librarys: [],
  library: null,
  sortBy: null,
  create: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'materi',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingLibrary = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingLibrary = false;
      state.error = action.payload;
    },

    // GET Librarys
    getLibrarysSuccess(state, action) {
      state.isLoadingLibrary = false;
      state.librarys = action.payload;
    },

    // GET Library
    getLibrarySuccess(state, action) {
      state.isLoadingLibrary = false;
      state.library = action.payload;
    },

    // Create Library
    createLibrarySuccess(state, action) {
      state.isLoadingLibrary = false;
      state.create = action.payload;
    },

    // Update Library
    updateLibrarySuccess(state, action) {
      state.isLoadingLibrary = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoadingLibrary = false;
      state.create = null;
    },

    //  SORT & FILTER Library
    sortByLibrary(state, action) {
      state.sortBy = action.payload;
    },

    filterLibrary(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByLibrary, filterLibrary } = slice.actions;

// ----------------------------------------------------------------------

export function getLibrarys() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Library[] } } } = await axios.get('/api/libraries');
      dispatch(slice.actions.getLibrarysSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLibrary(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Library } } } = await axios.get(
        '/api/detail-libraries/' + id
      );
      dispatch(slice.actions.getLibrarySuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createLibrary(payload: CreateLibrary) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/create-libraries', payload);
      dispatch(slice.actions.createLibrarySuccess(response.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateLibrary(payload: CreateLibrary, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-libraries/${id}`, payload);
      dispatch(slice.actions.updateLibrarySuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteLibrary(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-libraries/${id}`);
      dispatch(slice.actions.updateLibrarySuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetreateLibrary() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
