import { createSlice } from '@reduxjs/toolkit';
import { Library, LibraryUserState, AddEbooksUser } from 'types/digital_library_user';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: LibraryUserState = {
  isLoading: false,
  error: null,
  libraries: [],
  library: null,
  create: null,
  sortBy: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'digital_library_user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.library = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Librarys
    getLibrarysSuccess(state, action) {
      state.isLoading = false;
      state.libraries = action.payload;
    },

    // GET Library
    getLibrarySuccess(state, action) {
      state.isLoading = false;
      state.library = action.payload;
    },

    // Create Library
    createLibrarySuccess(state, action) {
      state.isLoading = false;
      state.create = action.payload;
    },

    // Update Library
    updateLibrarySuccess(state, action) {
      state.isLoading = false;
    },

    addEbooksDetailSuccess(state, action) {
      state.isLoading = false;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoading = false;
      state.create = null;
      state.libraries = [];
    },

    //  SORT & FILTER Librarys
    sortByLibrarys(state, action) {
      state.sortBy = action.payload;
    },

    filterLibrarys(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByLibrarys, filterLibrarys } = slice.actions;

// ----------------------------------------------------------------------

export function getlibraries(id?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let link = '/api/ebooks-user';

      if (id) {
        link = '/api/allebooks-user-details/' + id;
      }

      const response: { data: { result: Library[] } } = await axios.get(link);
      dispatch(slice.actions.getLibrarysSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLibrary(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Library } } } = await axios.get(
        `/api/ebooks-user-details/${id}`
      );
      dispatch(slice.actions.getLibrarySuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function submitAddEbooksManual(payload: AddEbooksUser) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post(`/api/add-ebooks-user`, payload);

      dispatch(slice.actions.addEbooksDetailSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export const submitAddReviewEbook = async (payload: any) => {
  try {
    const response: {
      data: {
        message: string;
      };
    } = await axios.post(`/api/review-ebooks`, payload);
    return { status: true, message:response.data.message };
  } catch (error) {
    return { status: false, message: error.response.data.message };
  }
}

// ----------------------------------------------------------------------

export function deleteEbooksUserActive(id: string, id_student: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-ebooks-user/${id}/${id_student}`);
      dispatch(slice.actions.addEbooksDetailSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}