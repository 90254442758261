import { createSlice } from '@reduxjs/toolkit';
import { KamusState, Kamus } from 'types/kamus';
// utils
import axios from 'utils/axios';
//
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: KamusState = {
  isLoading: false,
  error: null,
  all_kamus: [],
  single_kamus: null,
  sortBy: null,
  create: null,
  filters: {
    name: '',
  },
};

const slice = createSlice({
  name: 'kamus',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET All Kamus
    getAllKamusSuccess(state, action) {
      state.isLoading = false;
      state.all_kamus = action.payload;
      state.single_kamus = null;
    },

    // GET Single kamus
    getSingleKamusSuccess(state, action) {
      state.isLoading = false;
      state.single_kamus = action.payload;
    },

    // Create Kamus
    createKamusSuccess(state, action) {
      state.isLoading = false;
      state.create = action.payload;
    },

    // Update Kamus
    updateKamusSuccess(state, action) {
      state.isLoading = false;
      state.create = action.payload;
    },

    // Reset Create
    resetCreate(state) {
      state.isLoading = false;
      state.create = null;
    },

    //  SORT & FILTER kamus
    sortByLibrary(state, action) {
      state.sortBy = action.payload;
    },

    filterLibrary(state, action) {
      state.filters.name = action.payload.name;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByLibrary, filterLibrary } = slice.actions;

// ----------------------------------------------------------------------

export function getAllKamus() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: Kamus[] } } = await axios.get('/api/kamus');
      dispatch(slice.actions.getAllKamusSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSingleKamus(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { result: { data: Kamus } } } = await axios.get(
        `/api/detail-kamus/${id}`
      );
      dispatch(slice.actions.getSingleKamusSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createKamus(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.post('/api/create-kamus', payload);
      dispatch(slice.actions.createKamusSuccess(response.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateKamus(payload: any, id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.put(`/api/update-kamus/${id}`, payload);
      dispatch(slice.actions.updateKamusSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteKamus(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: {
          message: string;
        };
      } = await axios.delete(`/api/delete-kamus/${id}`);
      dispatch(slice.actions.updateKamusSuccess(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.message;
    }
  };
}

// ----------------------------------------------------------------------

export function resetKamus() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetCreate());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
